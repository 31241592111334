import Vue from 'vue';
import { Toast, Dialog, CellGroup, Field, Button, List, PullRefresh, NavBar, Tab, Tabs, Uploader, Step, Steps, Picker, Popup, Area, Divider, DatetimePicker, Switch, Icon, RadioGroup, Radio } from 'vant';


[Toast, Dialog, CellGroup, Field, Button, List, PullRefresh, NavBar, Tab, Tabs, Uploader, Step, Steps, Picker, Popup, Area, Divider, DatetimePicker, Switch, Icon, RadioGroup, Radio].forEach(component => {
  Vue.use(component)
})


Vue.prototype.$toast = Toast;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$loading = options => Toast.loading({
  forbidClick: true,
  duration: 0,
  loadingType: 'spinner',
  ...options,
});
Vue.prototype.$loading.close = options => Toast.clear(options);
