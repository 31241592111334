<template>
  <div class="case" @click="goDetail(item)">
    <div class="top">
      <div class="l">客户名称：{{ item.customerName }}</div>
      <div class="r danger" v-if="item.currentOverdueDays > 0">
        逾期天数：{{ item.currentOverdueDays }} 天
      </div>
    </div>
    <div class="content">
      <div class="attrs">
        <div>电话：{{ item.mobile }}</div>
        <div>{{ item.merchantRegion }}</div>
        <div>逾期金额：{{ item.currentOverdueAmount | amount }}</div>
        <div>派单时间：{{ item.assignTime }}</div>
      </div>
      <div class="status">
        <span
          :class="{
              PROCESSING: 'warning',
              APPROVALING: 'warning',
              FINISHED: 'success',
              OVERDUE_CANCEL: 'danger',
              CANCELED: 'danger',
            }[item.status]"
        >
          {{ item.status | enumMessage(getHomeStatusEnum) }}</span
        >
      </div>
      <div v-if="item.iouStatus == 'CLEAR'" class="tag">已结清</div>
      <div v-if="item.status == 'PROCESSING' && item.latestResults == 'ROLLBACK'" class="tag warning">驳回</div>
    </div>
    <div class="line"></div>
    <div class="footer">
      <div class="tips">
        <span class="danger" v-if="item.unFeedbackDay > 0 && !['FINISHED', 'OVERDUE_CANCEL'].includes(item.status)"
          ><i class="iconfont icon-infofill"></i
          >{{ item.unFeedbackDay }}天未反馈</span
        >
      </div>
      <div class="buttons">
        <button
          v-if="['APPROVALING', 'PROCESSING'].includes(item.status)"
          class="expire"
          @click.stop="onActionClick('DelayRequest', item)"
        >
          续期申请
        </button>
        <button
          class="feedback"
          @click.stop="onActionClick('FeedBackRequest', item)"
        >
          反馈
        </button>
        <button
          class="close"
          v-if="['PROCESSING'].includes(item.status)"
          @click.stop="onActionClick('Settle', item)"
        >
          结案
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    onActionClick(routeName, item) {
      this.$router.push({
        name: routeName,
        query: { homeNo: item.homeNo, assignNo: item.assignNo, orgName: item.orgName,homeStatus:item.status },
      });
    },

    // 详情
    goDetail(item) {
      // 只有处理中 & 待审核可查看详情
      if (!["PROCESSING", "APPROVALING"].includes(item.status)) {
        return;
      }
      this.$router.push({
        name: "Detail",
        params: {
          homeNo: item.homeNo,
        },
      });
    },
  },
  mounted() {
    this.fetchEnums("getHomeStatusEnum");
  },
};
</script>
<style lang="less" scoped>
.success {
  color: #07c160;
}
.danger {
  color: #ff4848;
}
.warning {
  color: #fa9540;
}
.case {
  background: #ffffff;
  border-radius: 4px;
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    left: 0px;
    top: 12px;
    width: 4px;
    height: 32px;
    background: #3c86ff;
    border-radius: 0 100px 100px 0px;
  }

  .top {
    padding: 18px 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 14px;
    letter-spacing: 0;
    color: #2b2e32;

    .l {
      font-weight: bold;
    }
    .r {
      font-weight: 500;
    }
  }

  .content {
    position: relative;
    display: flex;
    .attrs {
      flex: 1;
      padding: 0px 16px 18px 17px;
      div {
        color: #787b84;
        font-size: 12px;
        line-height: 1.8em;
      }
    }
    .status {
      font-size: 12px;
      letter-spacing: 0;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0px 20px 0px 0px;
    }
    .tag {
      position: absolute;
      right: 0px;
      bottom: 10px;
      padding: 8px 10px 8px 16px;
      background: #7bd17c;
      border-radius: 100px 0 0 100px;
      font-size: 12px;
      color: #ffffff;
      letter-spacing: 0;

      &.warning{
        background:#fa9540;
      }
    }
  }
  .line {
    height: 1px;
    background: #f1f2f7;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    height: 47px;
    line-height: 47px;
    font-size: 12px;
    .tips {
      padding-left: 15px;
      .iconfont {
        margin-right: 5px;
        font-size: 13px;
      }
    }

    .buttons {
      button {
        font-size: 12px;
        letter-spacing: 0;
        font-weight: 500px;
        border: 1px solid currentColor;
        border-radius: 2px;
        padding: 7px 18px;
        margin-right: 9px;
        background: transparent;
        box-sizing: border-box;

        &.expire {
          color: #ff9f00;
        }
        &.feedback {
          color: #4cb84c;
        }
        &.close {
          background: #3c86ff;
          color: #fff;
          border-color: #3c86ff;
        }
      }
    }
  }
}
</style>