import Vue from 'vue'
import '@/plugins/vant';
import App from './App.vue'
import '@/assets/css/reset.css'; // 重置样式 css
import '@/assets/css/global.less'; // 全局样式
import 'vant/lib/index.less';
import router from './router'
import store from './store'

Vue.config.productionTip = false


import mixins from './mixins'
Vue.mixin(mixins)

import './filters'

import Components from './components';
Vue.use(Components)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
