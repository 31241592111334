<template>
  <van-uploader
    v-model="fileList"
    :after-read="afterRead"
    :deletable="!readonly"
    :max-count="readonly ? 0 : undefined"
    :accept="accept"
  />
</template>
<script>
import * as _ from "lodash";
import Api from "@/api/user";
import { Toast } from "vant";
export default {
  props: {
    accept: {
      type: String,
      default: () => "*/*",
    },
    value: {
      type: Array,
    },
    readonly: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    const fileList = (this.value || []).map((item) => {
      if (item.constructor === String) {
        return {
          url: item,
        };
      }
      return item;
    });
    return {
      fileList,
    };
  },
  watch: {
    fileList: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$emit(
          "input",
          (val || []).map((item) => item.url)
        );
      },
    },
    value: {
      deep: true,
      handler(value) {
        const { fileList } = this;
        if (
          _.xor(
            fileList.map((item) => item.url),
            value
          ).length > 0
        ) {
          const newFileList = (value || []).map((item) => {
            if (item.constructor === String) {
              return {
                url: item,
              };
            }
            return item;
          });

          this.fileList = newFileList;
        }
      },
    },
  },
  methods: {
    async afterRead(file) {
      try {
        file.status = "uploading";
        file.message = "上传中...";
        const data = new FormData();
        data.append("file", file.file);
        const res = await Api.uploadFile(data);
        const { ossAccessUrl } = res.data;
        Object.assign(file, {
          url: ossAccessUrl,
          status: "done",
        });
      } catch (error) {
        this.fileList.splice(this.fileList.indexOf(file), 1);
        Toast.fail(error);
      }
    },
  },
};
</script>