import storage from '@/libs/storage'
import Api from '@/api/caseHandle'


const USER_INFO_KEY = 'USER_INFO'
const USER_TOKEN_KEY = 'USER_TOKEN'

export function storeUserInfo(userInfo) {
    storage.setLocal(USER_INFO_KEY, userInfo)
    if (userInfo.tokenInfo) {
        storeToken(userInfo.tokenInfo.access_token)
    }
}

export function getUserInfo() {
    return storage.getLocal(USER_INFO_KEY)
}

export function storeToken(token) {
    storage.setLocal(USER_TOKEN_KEY, token)
}

export function getToken() {
    return storage.getLocal(USER_TOKEN_KEY)
}


export function clearUserInfo() {
    storage.removeLocal(USER_INFO_KEY)
    storage.removeLocal(USER_TOKEN_KEY)
}


export async function loadRegions() {
    try {
        var res = await Api.regions()
        const { data } = res;
        const province_list = data.filter(item => item.parentKey === '100000')
        const province_code_list = province_list.map(item => item.regionCode);
        const city_list = data.filter(item => province_code_list.includes(item.parentKey))
        const city_code_list = city_list.map(item => item.regionCode);
        const county_list = data.filter(item => city_code_list.includes(item.parentKey))

        const province = {};
        province_list.forEach(item => {
            province[item.regionCode] = item.regionName
        });

        const city = {};
        city_list.forEach(item => {
            city[item.regionCode] = item.regionName
        });

        const county = {};
        county_list.forEach(item => {
            county[item.regionCode] = item.regionName
        });

        return {
            province_list: province,
            city_list: city,
            county_list: county,
        }
    } catch (error) {
        console.warn(error)
    }
    return {}
}