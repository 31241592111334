import CardCustomer from './CardCustomer'
import Uploader from './Uploader'
import UserIcon from './UserIcon'
import TabBarNav from './TabBar'
import CaseItem from './CaseItem'

export default {
    install(Vue) {
        Vue.component('CardCustomer', CardCustomer)
        Vue.component('Uploader', Uploader)
        Vue.component('UserIcon', UserIcon)
        Vue.component('TabBarNav', TabBarNav)
        Vue.component('CaseItem', CaseItem)
    }
};