import Vue from 'vue';
import VueRouter from 'vue-router';
import { getToken } from '@/libs/utils'

import indexRouter from './routes/index';

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history', // "hash" | "history" | "abstract"
  fallback: false, // 当浏览器不支持 history.pushState 控制路由是否应该回退到 hash 模式。默认值为 true。
  routes: [...indexRouter.basic, ...indexRouter.modules]
})

router.beforeEach((to, from, next) => {
  if (to.meta.anonymous) {
    return next()
  }
  const token = getToken()
  if (!token) {
    const query = {
      redirect_path: to.path
    }
    return next({ name: 'Login', query })
  }
  next()
})


export default router
