// import config from '@/config';
import axios from '@/libs/api.request';
import storage from '@/libs/storage';

const Api = {};

/**
 * @description 取消家访
 */
Api.cancel = opts => axios.request({
    url: '/api/app/home/caseHandle/cancel',
    data: opts,
});

/**
 * @description 家访详情
 */
Api.findDetailByHomeNo = homeNo => axios.request({
    url: `/api/app/home/caseHandle/findDetailByHomeNo/${homeNo}`,
    method: 'get'
});

/**
 * @description 调度中心家访记录列表
 */
Api.findPageISO = opts => axios.request({
    url: '/api/app/home/caseHandle/findPageISO',
    data: opts,
});


/**
 * @description 确认接单
 */
Api.receive = opts => axios.request({
    url: '/api/app/home/caseHandle/receive',
    data: opts,
});

/**
 * @description 查询补充信息
 */
Api.reportAdditionList = assignNo => axios.request({
    url: '/api/app/home/caseHandle/reportAdditionList',
    data: {},
    params: {
        assignNo
    }
});


/**
 * @description 查询结案申请内容
 */
Api.getFinishApply = assignNo => axios.request({
    url: `/api/app/home/caseHandle/getFinishApply`,
    method: 'get',
    params: {
        assignNo
    }
});

/**
 * @description 新增结案申请
 */
Api.addFinishApply = data => axios.request({
    url: '/api/app/home/caseHandle/addFinishApply',
    data: data,
    params: {
    }
});


/**
 * @description 保存结案申请2
 */
 Api.finishApply2 = data => axios.request({
    url: '/api/app/home/caseHandle/finishApply2',
    data: data,
    params: {
    }
});

/**
 * @description 修改结案申请
 */
 Api.updateFinishApply = data => axios.request({
    url: '/api/app/home/caseHandle/updateFinishApply',
    data: data,
    params: {
    }
});





/**
 * @description 家访报告核实信息详情
 */
Api.reportVerifyInfo = assignNo => axios.request({
    url: `/api/app/home/caseHandle/reportVerifyInfo/${assignNo}`,
    method: 'get'
});

/**
 * @description 保存补充信息
 */
Api.saveHomeReportAddition = opts => axios.request({
    url: '/api/app/home/caseHandle/saveHomeReportAddition',
    data: opts,
});


/**
 * @description 保存家访反馈信息详情
 */
Api.saveReportFeedbackInfo = opts => axios.request({
    url: '/api/app/home/caseHandle/saveReportFeedbackInfo',
    data: opts,
});


/**
 * @description 保存家访报告核实信息
 */
Api.saveReportVerifyInfo = opts => axios.request({
    url: '/api/app/home/caseHandle/saveReportVerifyInfo',
    data: opts,
});


/**
 * @description 保存家访报告核实信息
 */
Api.homeResultConfigList = () => axios.request({
    url: '/api/app/home/caseHandle/homeResultConfigList',
    data: {},
});

/**
 * @description 区域列表
 */
Api.regions = async () => {
    const KEY = 'REGIONS'
    var res = storage.getLocal(KEY);
    if (res != null) {
        return res
    }
    res = await axios.request({
        url: `/api/app/home/caseHandle/regions`,
        method: 'get'
    });
    storage.setLocal(KEY, res)
    return res;
};


// 查询首页统计
Api.getOrgIndexCount = async () => {
    const res = await axios.request({
        url: `/api/app/home/caseHandle/getOrgIndexCount`,
        method: 'get'
    });
    return res
}


// 新增延期申请
Api.addDelayApply = opts => axios.request({
    url: '/api/app/home/caseHandle/addDelayApply',
    data: opts,
    params: {}
});


// 查询延期申请记录
Api.findDelayApplyList = opts => axios.request({
    url: '/api/app/home/caseHandle/findDelayApplyList',
    data: opts,
});

// 新增案件反馈记录
Api.addFeedback = opts => axios.request({
    url: '/api/app/home/caseHandle/addFeedback',
    data: opts,
    params: {}
});


// 查询案件反馈记录
Api.findFeedbackList = opts => axios.request({
    url: '/api/app/home/caseHandle/findFeedbackList',
    data: opts,
    params: {}
});



export default Api